import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Form, Row, Col, Button, Checkbox, Typography, Tooltip, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import {
  USER_REGISTRATION_ERRORS,
  USER_REGISTRATION_ERRORS_TRANSLATION_MAP,
} from 'constants/users';
import { FORM_FIELD_NAMES } from 'constants/selfRegistration';
import { RECAPTCHA_SITE_KEY, COUNTRY_LINKS, SUPPORTED_COUNTRIES } from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { CORPORATE_CLIENT_COUNTRIES } from 'constants/corporateClients';
import useAmplitude from 'utils/hooks/useAmplitude';
import useTranslation from 'utils/hooks/useTranslation';
import useIsFormValid from 'utils/hooks/useIsFormValid';
import { getBrowserCountryBasedOnLanguage } from 'utils/browserLanguageCountryUtil';
import useValidateFormOnLocaleChange from 'utils/hooks/useValidateFormOnLocaleChange';
import InputWithCheckmarkValidation from 'components/Shared/Page/InputWithCheckmarkValidation';
import PasswordInputWithCheckmarkValidation from 'components/Shared/Page/PasswordInputWithCheckmarkValidation';
import PhoneInputWithCheckmarkValidation from 'components/Shared/Page/PhoneInputWithCheckmarkValidation';

import styles from './style.module.scss';

const { Title, Text } = Typography;
const { Option } = Select;
const { Item } = Form;
const layout = {
  labelCol: { span: 24 },
};

const RegistrationFormV2 = ({ isMobile, onRegister, isRegistrationLoading, registrationError }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const recaptchaRef = useRef(null);
  const locale = useSelector(state => state.settings.locale);
  const { translateText } = useTranslation();
  const { hasAcceptedFunctionalCookies } = useSelector(state => state.settings);

  const [form] = Form.useForm();
  const { getFieldValue, validateFields } = form;

  const { isFormValid, validateForm } = useIsFormValid(form);
  const [isBusinessNameValid, setIsBusinessNameValid] = useState(false);
  const [isNoOfDriversValid, setIsNoOfDriversValid] = useState(false);
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isRecaptchaResetRequired, setIsRecaptchaResetRequired] = useState(true);
  const [duplicateEmail, setDuplicateEmail] = useState(null);
  const [corporateClientAlreadyExists, setCorporateClientAlreadyExists] = useState(null);

  const browserCountry = getBrowserCountryBasedOnLanguage(locale);
  const nameColsSpan = isMobile ? 24 : 12;

  const generateToken = token => {
    setRecaptchaToken(token);
  };

  const handleFieldChange = (fieldName, setValidationState) => {
    validateFields([fieldName])
      .then(() => {
        setValidationState(true);
      })
      .catch(() => {
        setValidationState(false);
      });
  };

  const handleSubmit = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationRegistrationButton);
    validateFields()
      .then(values => {
        onRegister({ ...values, recaptchaToken: recaptchaToken, preferredLanguage: locale });
        setDuplicateEmail(null);
        setCorporateClientAlreadyExists(null);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (isRegistrationLoading) {
      setIsRecaptchaResetRequired(true);
    }
    if (!isRegistrationLoading && isRecaptchaResetRequired) {
      recaptchaRef.current?.reset();
      setIsRecaptchaResetRequired(false);
    }
  }, [isRecaptchaResetRequired, isRegistrationLoading]);

  useEffect(() => {
    if (!registrationError) {
      return;
    }

    if (
      (registrationError === 'DuplicateEmail' || registrationError === 'UserEmailDomainNotValid') &&
      !duplicateEmail
    ) {
      setDuplicateEmail(getFieldValue(FORM_FIELD_NAMES.email));
      const emailError = {
        name: 'email',
        errors: [
          translateText(
            USER_REGISTRATION_ERRORS_TRANSLATION_MAP[USER_REGISTRATION_ERRORS[registrationError]],
          ),
        ],
      };

      form.setFields([emailError]);
    }

    if (
      registrationError === 'CorporateClientWithTheSameNameAlreadyExists' &&
      !corporateClientAlreadyExists
    ) {
      setCorporateClientAlreadyExists(getFieldValue(FORM_FIELD_NAMES.corporateClientName));
      const corporateClientNameError = {
        name: 'corporateClientName',
        errors: [
          translateText(
            USER_REGISTRATION_ERRORS_TRANSLATION_MAP[USER_REGISTRATION_ERRORS[registrationError]],
          ),
        ],
      };

      form.setFields([corporateClientNameError]);
    }
  }, [
    duplicateEmail,
    corporateClientAlreadyExists,
    form,
    getFieldValue,
    registrationError,
    translateText,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hasVParam = urlParams.has('v');
    if (hasAcceptedFunctionalCookies && !hasVParam) {
      setTimeout(() => {
        sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationUserRegistrationForm);
      }, 1000); // Add a 1-second delay to ensure Amplitude initialization is complete
    }
  }, [sendAmplitudeEvent, hasAcceptedFunctionalCookies]);

  useValidateFormOnLocaleChange(form);

  return (
    <>
      <Form
        form={form}
        requiredMark="optional"
        id={styles.registrationForm}
        {...layout}
        onFieldsChange={validateForm}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Title level={3} className={styles.formTitleV1}>
              {translateText('corporateAccounts.selfRegistration.form.title')}
            </Title>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            span={24}
            onFocus={() =>
              sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationCountryFieldClicked)
            }
          >
            <Item
              name={FORM_FIELD_NAMES.country}
              label={translateText('corporateAccounts.corporateClient.country')}
              rules={[
                {
                  required: true,
                  message: translateText(
                    'corporateAccounts.corporateClient.country.error.presence',
                  ),
                },
              ]}
            >
              <Select
                className={styles.dropDownContainer}
                placeholder={translateText('corporateAccounts.corporateClient.country.placeholder')}
                data-testid={FORM_FIELD_NAMES.country}
              >
                {SUPPORTED_COUNTRIES.map(({ label, countryCode }) => (
                  <Option key={countryCode} value={countryCode}>
                    {translateText(`corporateAccounts.countries.${countryCode.toLowerCase()}`)}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            span={nameColsSpan}
            onFocus={() =>
              sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationBusinessNameFieldClicked)
            }
          >
            <Item
              name={FORM_FIELD_NAMES.corporateClientName}
              label={translateText('corporateAccounts.common.businessName')}
              data-testid={FORM_FIELD_NAMES.corporateClientName}
              rules={[
                {
                  required: true,
                  message: translateText(
                    'corporateAccounts.corporateClient.businessName.validationError.businessNameRequired',
                  ),
                },
                {
                  max: 50,
                  message: translateText(
                    'corporateAccounts.corporateClient.businessName.validationError.characterLimitExceeded',
                  ),
                },
                {
                  validator: (_, value) => {
                    if (value === corporateClientAlreadyExists) {
                      return Promise.reject(
                        translateText('corporateAccounts.user.registration.duplicateBusinessName'),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputWithCheckmarkValidation
                onChange={() =>
                  handleFieldChange(FORM_FIELD_NAMES.corporateClientName, setIsBusinessNameValid)
                }
                isValid={isBusinessNameValid}
                appearance={'largeInput'}
              />
            </Item>
          </Col>
          <Col
            span={nameColsSpan}
            onFocus={() =>
              sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationDriverCountFieldClicked)
            }
          >
            <Item
              name={FORM_FIELD_NAMES.numberOfDrivers}
              label={
                <Tooltip
                  title={translateText('corporateAccounts.corporateClient.fleetSize.tooltip')}
                >
                  {translateText('corporateAccounts.corporateClient.fleetSize')}
                  <InfoCircleOutlined
                    className="infoCircle"
                    style={{ fontSize: '16px', color: '#222222' }}
                  />
                </Tooltip>
              }
              data-testid={FORM_FIELD_NAMES.numberOfDrivers}
              validateFirst
              rules={[
                {
                  required: true,
                  message: translateText(
                    'corporateAccounts.corporateClient.fleetSize.validationError.fleetSizeRequired',
                  ),
                },
                {
                  pattern: /^[0-9]*$/,
                  message: translateText(
                    'corporateAccounts.corporateClient.fleetSize.validationError.numericOnly',
                  ),
                },
                {
                  max: 6,
                  message: translateText(
                    'corporateAccounts.corporateClient.fleetSize.validationError.maxDigitsExceeded',
                  ),
                },
              ]}
            >
              <InputWithCheckmarkValidation
                onChange={() =>
                  handleFieldChange(FORM_FIELD_NAMES.numberOfDrivers, setIsNoOfDriversValid)
                }
                isValid={isNoOfDriversValid}
                appearance={'largeInput'}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            span={nameColsSpan}
            onFocus={() =>
              sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationFirstNameFieldClicked)
            }
          >
            <Item
              name={FORM_FIELD_NAMES.firstName}
              label={translateText('corporateAccounts.drivers.firstName')}
              data-testid={FORM_FIELD_NAMES.firstName}
              rules={[
                {
                  required: true,
                  message: translateText('corporateAccounts.admins.form.firstNameRequired'),
                },
                {
                  max: 50,
                  message: translateText('corporateAccounts.drivers.form.firstNameValidation'),
                },
              ]}
            >
              <InputWithCheckmarkValidation
                onChange={() => handleFieldChange(FORM_FIELD_NAMES.firstName, setIsFirstNameValid)}
                isValid={isFirstNameValid}
                appearance={'largeInput'}
              />
            </Item>
          </Col>
          <Col
            span={nameColsSpan}
            onFocus={() =>
              sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationLastNameFieldClicked)
            }
          >
            <Item
              name={FORM_FIELD_NAMES.lastName}
              label={translateText('corporateAccounts.drivers.lastName')}
              data-testid={FORM_FIELD_NAMES.lastName}
              rules={[
                {
                  required: true,
                  message: translateText('corporateAccounts.admins.form.lastNameRequired'),
                },
                {
                  max: 50,
                  message: translateText('corporateAccounts.drivers.form.lastNameValidation'),
                },
              ]}
            >
              <InputWithCheckmarkValidation
                onChange={() => handleFieldChange(FORM_FIELD_NAMES.lastName, setIsLastNameValid)}
                isValid={isLastNameValid}
                appearance={'largeInput'}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            span={24}
            onFocus={() =>
              sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationEmailFieldClicked)
            }
          >
            <Item
              name={FORM_FIELD_NAMES.email}
              label={translateText('corporateAccounts.common.businessEmail')}
              data-testid={FORM_FIELD_NAMES.email}
              rules={[
                {
                  required: true,
                  message: translateText('corporateAccounts.drivers.form.emailRequired'),
                },
                {
                  type: 'email',
                  message: translateText('corporateAccounts.drivers.form.emailValidation'),
                },
                {
                  validator: (_, value) => {
                    if (value === duplicateEmail) {
                      return Promise.reject(
                        translateText('corporateAccounts.user.registration.duplicateEmail'),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputWithCheckmarkValidation
                onChange={() => handleFieldChange(FORM_FIELD_NAMES.email, setIsEmailValid)}
                isValid={isEmailValid}
                appearance={'largeInput'}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            span={24}
            onFocus={() =>
              sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationPhoneNumberFieldClicked)
            }
          >
            <PhoneInputWithCheckmarkValidation
              translateText={translateText}
              isValid={isPhoneNumberValid}
              onChange={setIsPhoneNumberValid}
              form={form}
              validateForm={validateForm}
              onlyCountries={Object.values(CORPORATE_CLIENT_COUNTRIES)}
              appearance={'largeInput'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            span={24}
            onFocus={() =>
              sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationPasswordFieldClicked)
            }
          >
            <PasswordInputWithCheckmarkValidation
              translateText={translateText}
              appearance={'largeInput'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} className={styles.recaptchaContainer}>
            <ReCAPTCHA
              ref={recaptchaRef}
              data-testid="recaptcha"
              id="recaptcha"
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={generateToken}
              onExpired={() => setRecaptchaToken(null)}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item
              name={FORM_FIELD_NAMES.termsAndPrivacyAgreement}
              initialValue={false}
              valuePropName="checked"
              data-testid={FORM_FIELD_NAMES.termsAndPrivacyAgreement}
              rules={[
                {
                  validator: (rule, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          translateText(
                            'corporateAccounts.common.termsAndPrivacyAgreementRequired',
                          ),
                        ),
                },
              ]}
            >
              <Checkbox
                onClick={() =>
                  sendAmplitudeEvent(
                    AMPLITUDE_EVENT_TYPES.selfRegistrationTermsAndConditionsCheckbox,
                  )
                }
              >
                {translateText('corporateAccounts.selfRegistration.form.agreement', {
                  subscriptionAgreement: (
                    <a
                      href={COUNTRY_LINKS[browserCountry]?.subscriptionAgreement}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        sendAmplitudeEvent(
                          AMPLITUDE_EVENT_TYPES.selfRegistrationTermsAndConditionsSubscriptionLink,
                        )
                      }
                      className={`${styles.buttonAsLink} ${styles.hyperlink}`}
                      data-testid="subscriptionAgreementLink"
                    >
                      {translateText('corporateAccounts.common.subscriptionAgreement')}
                    </a>
                  ),
                  privacyPolicy: (
                    <a
                      href={COUNTRY_LINKS[browserCountry]?.privacyPolicy}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        sendAmplitudeEvent(
                          AMPLITUDE_EVENT_TYPES.selfRegistrationTermsAndConditionsPrivacyPolicyLink,
                        )
                      }
                      className={`${styles.buttonAsLink} ${styles.hyperlink}`}
                      data-testid="privacyPolicyLink"
                    >
                      {translateText('corporateAccounts.common.privacyPolicy')}
                    </a>
                  ),
                })}
              </Checkbox>
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} className={styles.createAccountButton}>
            <Button
              type="primary"
              size="large"
              data-testid="createAccountButton"
              onClick={() => handleSubmit()}
              disabled={!isFormValid || !recaptchaToken}
              loading={isRegistrationLoading}
            >
              {translateText('corporateAccounts.corporateClient.createAccount')}
            </Button>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Text id={styles.contactSales}>
              <Text>{translateText('corporateAccounts.common.questions')}</Text>
              <Text>{'? '}</Text>
              <a
                href={COUNTRY_LINKS[browserCountry]?.contactSales}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationContactSalesLink)
                }
                className={`${styles.buttonAsLink} ${styles.contactSalesText}`}
                data-testid="contactSalesLink"
              >
                {`${translateText('corporateAccounts.common.contactSales')}`}
              </a>
            </Text>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RegistrationFormV2;
