import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button } from 'antd';
import Cookies from 'js-cookie';
import { USER_ROLES } from 'constants/users.js';
import { AMPLITUDE_EVENT_TYPES, AMPLITUDE_USER_ACTIONS } from 'constants/amplitude';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import useGetFaqLink from 'utils/hooks/useGetFaqLink.js';
import { useFeatureFlag } from 'components/Providers/FeatureFlag';
import { optOutDatesByCountry } from './optOutDatesByCountry.js';
import ExtenededModal from 'components/Shared/Modal';

import styles from './style.module.scss';

const SmsReminderEnrollmentModal = () => {
  const { currentCorporation, corporationRole } = useSelector(state => state.user);
  const { showFeature } = useFeatureFlag();
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();
  const faqLink = useGetFaqLink();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const excludedUserRoles = useMemo(() => {
    return [USER_ROLES.REPORTING.value, USER_ROLES.SERVICE_ADMIN.value];
  }, []);

  const COOKIE_NAME = 'smsReminderEnrollmentModal';

  useEffect(() => {
    if (
      showFeature('smsReminder') &&
      currentCorporation &&
      !excludedUserRoles.includes(corporationRole) &&
      !Cookies.get(COOKIE_NAME)
    ) {
      setIsModalVisible(true);
    }
  }, [corporationRole, currentCorporation, showFeature, excludedUserRoles]);

  const handleClose = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.smsReminderEnrollmentModal, {
      userAction: AMPLITUDE_USER_ACTIONS.closeClicked,
    });

    Cookies.set(COOKIE_NAME, 'true');
    setIsModalVisible(false);
  };

  return (
    <ExtenededModal
      title={
        <Typography component="h3" className={styles.modalTitle}>
          {translateText('corporateAccounts.modal.smsEnrollment.title')}
        </Typography>
      }
      width={560}
      visible={isModalVisible}
      className={styles.modalContainer}
      closable={false}
      footer={[
        <div key="footer" className={styles.footer}>
          <Button key="close" onClick={() => handleClose()} className={`${styles.closeButton}`}>
            {translateText('corporateAccounts.common.status.close')}
          </Button>
        </div>,
      ]}
    >
      <div className={styles.content}>
        <Typography className={styles.contentTitle}>
          {translateText('corporateAccounts.modal.smsEnrollment.importantUpdate')}
        </Typography>
        <Typography>{translateText('corporateAccounts.modal.smsEnrollment.content')}</Typography>
        <Typography>
          {translateText('corporateAccounts.modal.smsEnrollment.deadline', {
            optOutDate: optOutDatesByCountry[currentCorporation?.country],
          })}
        </Typography>
        <Typography>
          {translateText('corporateAccounts.modal.smsEnrollment.moreInfo', {
            faq: (
              <a
                href={faqLink}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.buttonAsLink} ${styles.hyperlink}`}
              >
                {translateText('corporateAccounts.common.faq')}
              </a>
            ),
          })}
        </Typography>
      </div>
    </ExtenededModal>
  );
};

export default SmsReminderEnrollmentModal;
