// When enabling the SMS reminder feature flag (smsReminder), make sure to update these dates as well.

export const optOutDatesByCountry = {
  CA: 'December 10, 2025',
  US: 'December 11, 2025',
  GB: 'December 12, 2025',
  FR: 'December 13, 2025',
  CH: 'December 14, 2025',
  DE: 'December 15, 2025',
};
